<template>
  <div class="container">
    <img src="../../../assets/logo.png" height="50">
    <h3>Appuie sur la touche Entrée pour valider ta réponse</h3>
    <div class="panel-heading">
      <h2 class="panel-title text-center">{{ question }}</h2>
    </div>
    <div class="panel-body">
      <input type="text" @keyup.enter="onAnswer" v-model="currentAnswer">
      <p>{{ text }}</p>
    </div>

    <div class="panel-body">
      <p>Score : {{ score }}</p>
    </div>
  </div>
</template>

<script>
const MODE_ADDITION = 1;
const MODE_SUBTRACTION = 2;
const MODE_MULTIPLICATION = 3;
const MODE_DIVISION = 4;
export default {
  name: 'MathSession',
  props: {
    options: {
      type: Object,
      // Object or array defaults must be returned from
      // a factory function
      default: function () {
        return {
          multiplication: {
            isActive: false,
            min1: 0,
            max1: 10,
            min2: 0,
            max2: 10
          },
          division: {
            isActive: false,
            min1: 0,
            max1: 100,
            min2: 1,
            max2: 10,
            integer: true
          },
          addition: {
            isActive: true,
            min: 0,
            max: 100
          },
          substraction: {
            isActive: false,
            min: 0,
            max: 100
          },
        };
      }
    }
  },
  data() {
    return {
      timer: null,
      question: 'Oops, an error occurred :/',
      score: 0,
      timeLimit: 60,
      correctAnswer: 0,
      currentAnswer: null,
      operationsSelected: [1],
      text: ''
    };
  },
  methods: {
    initSession(){
      this.operationsSelected = [];
      if (this.options.addition.isActive) this.operationsSelected.push(1);
      if (this.options.substraction.isActive) this.operationsSelected.push(2);
      if (this.options.multiplication.isActive) this.operationsSelected.push(3);
      if (this.options.division.isActive) this.operationsSelected.push(4);
      this.timer = setTimeout(this.finishSession, this.timeLimit * 1000); // up
    },
    generateQuestion() {
      let firstNumber;
      let secondNumber;
      let mode = this.operationsSelected[this.generateRandomNumber(0, this.operationsSelected.length-1)];

      switch (mode) {
        case MODE_ADDITION:
          firstNumber = this.generateRandomNumber(this.options.addition.min, this.options.addition.max);
          secondNumber = this.generateRandomNumber(this.options.addition.min, this.options.addition.max - firstNumber);
          this.correctAnswer = firstNumber + secondNumber;
          this.question = `${firstNumber} + ${secondNumber} = `;
          break;
        case MODE_SUBTRACTION:
          firstNumber = this.generateRandomNumber(this.options.substraction.min, this.options.addition.max);
          secondNumber = this.generateRandomNumber(this.options.substraction.min, firstNumber);
          this.correctAnswer = firstNumber - secondNumber;
          this.question = `${firstNumber} - ${secondNumber} = `;
          break;
        case MODE_MULTIPLICATION:
          firstNumber = this.generateRandomNumber(this.options.multiplication.min1, this.options.multiplication.max1);
          secondNumber = this.generateRandomNumber(this.options.multiplication.min2, this.options.multiplication.max2);
          this.correctAnswer = firstNumber * secondNumber;
          this.question = `${firstNumber} x ${secondNumber} = `;
          break;
        case MODE_DIVISION:

          firstNumber = this.generateRandomNumber(this.options.division.min1, this.options.division.max1);
          secondNumber = this.generateRandomNumber(this.options.division.min2, this.options.division.max2);

          this.correctAnswer = secondNumber;
          secondNumber = this.correctAnswer * firstNumber;
          this.question = `${secondNumber} : ${firstNumber} = `;
          break;
        default:
          this.correctAnswer = 0;
          this.question = 'Oops, an Error occurred :/';
      }
    },
    generateRandomNumber(min, max, except) {
      const rndNumber = Math.floor(Math.random() * (max+1 - min)) + min;
      if (rndNumber == except) {
        return this.generateRandomNumber(min, max, except);
      }
      return rndNumber;
    },
    onAnswer() {
      // if true, new question and increase the score
      console.log(this.currentAnswer, this.correctAnswer);
      if (this.currentAnswer == this.correctAnswer) {
        this.score++;
        this.generateQuestion();
        this.text = "Bravo !";
      } else {
        this.text = "Essaie encore.";
      }
      this.currentAnswer = null;
      // if false, indicate it with red "flash" on the input and clean the field
    },
    finishSession(){
      this.$router.push({ name: 'mathSessionFinish', params: {result: this.score} });
    }
  },
  created: function () {
    this.initSession();
    this.generateQuestion();
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
