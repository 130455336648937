<template>
  <!-- 1 dropdown per type of operation
   When dropped, the option for the selected operation is displayed-->

  <div class="container">
    <img src="../../../assets/logo.png" height="50">
    <h2>Paramètres de l'exercice de mathématique</h2>
    <div class="row">
      <h3 class="col">
        <input class="checkbox" type="checkbox" id="checkboxAdd" v-model="options.addition.isActive"> Additions
      </h3>
      <div class="form-row" v-if="options.addition.isActive">
        <label for="minAdd">Minimum: </label><input id="minAdd" type="number" v-model.number="options.addition.min">
        <label for="maxAdd">Maximum: </label><input id="maxAdd" type="number" v-model.number="options.addition.max">
      </div>
    </div>
    <div class="row">
      <h3 class="col">
        <input class="checkbox" type="checkbox" id="checkboxSub" v-model="options.substraction.isActive"> Soustractions
      </h3>
      <div class="form-row" v-if="options.substraction.isActive">
        <label for="minSub">Minimum: </label><input id="minSub" type="number" v-model.number="options.substraction.min">
        <label for="maxSub">Maximum: </label><input id="maxSub" type="number" v-model.number="options.substraction.max">
      </div>
    </div>
    <div class="row">
      <h3 class="col">
        <input class="checkbox" type="checkbox" id="checkboxMult" v-model="options.multiplication.isActive"> Multiplications
      </h3>
      <div class="form-row" v-if="options.multiplication.isActive">
        <p> Nombre 1 x Nombre 2 </p>
        <label>Minimum pour le nombre 1 </label><input type="number" v-model.number="options.multiplication.min1"> <br/>
        <label>Maximum pour le nombre 1</label> <input type="number" v-model.number="options.multiplication.max1"> <br/>
        <label>Minimum pour le nombre 2 </label><input type="number" v-model.number="options.multiplication.min2"> <br/>
        <label>Maximum pour le nombre 2</label> <input type="number" v-model.number="options.multiplication.max2"> <br/>
      </div>
    </div>
    <div class="row">
      <h3 class="col">
        <input class="checkbox" type="checkbox" id="checkboxDiv" v-model="options.division.isActive"> Divisions
      </h3>
      <div class="form-row" v-if="options.division.isActive">
        <p> Nombre / Nombre 1 = Nombre 2 </p>
        <label>Minimum pour le nombre 1</label> <input type="number" v-model.number="options.division.min1"> <br/>
        <label>Maximum pour le nombre 1 </label><input type="number" v-model.number="options.division.max1"> <br/>
        <label>Minimum pour le nombre 2 </label><input type="number" v-model.number="options.division.min2"> <br/>
        <label>Maximum pour le nombre 2 </label><input type="number" v-model.number="options.division.max2"> <br/>
      </div>
    </div>
      <div class="dispo-box">
        <h3>Disponibilité: </h3>
        <div class="col">
          <input class="checkbox" type="checkbox" v-model="availability.local"> En classe
        </div>
        <div class="col">
          <input class="checkbox" type="checkbox" v-model="availability.online"> En ligne
        </div>
      </div>
      <button class="main-btn" v-on:click="launchSession">Commencer l'exercice</button>
  </div>
</template>

<script>
export default {
  name: 'MathParameters',
  data() {
    return {
      options: {
        multiplication: {
          isActive: false,
          min1: 0,
          max1: 10,
          min2: 0,
          max2: 10
        },
        division: {
          isActive: false,
          min1: 1,
          max1: 10,
          min2: 0,
          max2: 10,
          integer: true
        },
        addition: {
          isActive: false,
          min: 0,
          max: 100
        },
        substraction: {
          isActive: false,
          min: 0,
          max: 100
        },
      },
      availability: {
        local: true,
        online: false
      }
    }
  },
  methods: {
    launchSession(){
      this.$router.push({ name: 'mathSession', params: {options: this.options} })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input{
  max-width: 150px;
}

/*.container{
  grid-template-columns: 1fr 1fr;
  column-gap: 0px;
}

img,
h2,
div button,
.dispo-box{
  grid-column: 1/-1;
}*/


</style>
