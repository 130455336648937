<template>
  <div class="container">
    <img src="../../assets/logo.png" height="50">
    <h2>Confirmation de votre compte</h2>
    <div class="form-row">
      <p> Votre compte a été créé mais vous n'êtes pas encore lié à un utilisateur.</p>

      <p>Veuillez entrer le code que vous avez
        reçu:</p>
      <div class=""><input id="code" type="text" v-model="code">
      </div>
    </div>
    <label t-if="errorMessage.length > 0"> {{ errorMessage }}</label>
    <button class="main-btn" v-on:click="connect">S'enregistrer</button>
  </div>
</template>

<script>

import firebase from "firebase/app";

export default {
  name: 'LinkUser',
  data() {
    return {
      code: '',
      errorMessage: ""
    }
  },
  methods: {
    connect() {
      firebase.firestore().collection("users").where("auth_code", "==", this.code).limit(1).get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              firebase.firestore().collection("users").doc(doc.id).update({
                "user_auth_uid": firebase.auth().currentUser.uid,
                "auth_code": ""
              }).then(() => {
                let user = localStorage.user ;
                if (user.user_type == "1") {
                  this.$router.push('math/parameters');
                } else if (user.user_type == "2") {
                  this.$router.push('math/session');
                }
              })
                  .catch((error) => {
                    alert(error.code + " : " + error.message)
                  });
            });
          })
          .catch((error) => {
            console.log(error.code + " : " + error.message)
            return this.errorMessage = "Vérifiez bien le code entré."
          });


    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
