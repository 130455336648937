import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login";
import Register from "../views/Register";
import LinkUser from "../views/User/LinkUser";
import Users from "../views/User/Users";
import CreateUser from "../views/User/CreateUser";
import User from "../views/User/User";
import MathSession from "../views/Student/Math/Session";
import MathSessionFinish from "../views/Student/Math/SessionFinish";
import MathParameters from "../views/Teacher/Math/Parameters";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Login",
    component: Login,
}, {
    path: "/register",
    name: "Register",
    component: Register,
}, {
    path: "/link-user",
    name: "LinkUser",
    component: LinkUser,
},{
    path: "/users",
    name: "Users",
    component: Users,
},{
    path: "/user",
    name: "User",
    component: User,
    props: true
},{
    path: "/create-user",
    name: "CreateUser",
    component: CreateUser,
},
    {
        path: "/math/session",
        name: "mathSession",
        component: MathSession,
        props: true
    },
    {
        path: "/math/sessionfinished",
        name: "mathSessionFinish",
        component: MathSessionFinish,
        props: true
    },
    {
        path: "/math/parameters",
        name: "Paramètres",
        component: MathParameters,
    }

    //   {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    //   }
];

const router = new VueRouter({
    mode: process.env.IS_ELECTRON ? "hash" : "history",
    // base: process.env.BASE_URL,
    routes,
    // scrollBehavior(to, from, savedPosition) {
    //     if (savedPosition) {
    //         return savedPosition
    //     } else {
    //         return { x: 0, y: 0 }
    //     }
    // }
});

export default router;
