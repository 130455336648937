<template>
  <!-- 1 dropdown per type of operation
   When dropped, the option for the selected operation is displayed-->

  <div class="container">
    <div v-for="user in users" :key="user.id" v-on:click="displayUser(user)">
      <p> {{ user.first_name + " " + user.last_name }}</p>
    </div>
  </div>
</template>

<script>

import firebase from "firebase/app";

export default {
  name: 'Users',
  data() {
    return {
      users: [],
      errorMessage: ""
    }
  },
  methods: {
    displayUser(user){
      this.$router.push({ name: 'User', params: {uid: user.id} });

    }
  },
  created() {
    firebase.firestore().collection("classes").doc("u6rMss8MUJc9Ea5GNBv3").collection("students").get().then((querySnapshot) => {
    //firebase.firestore().collection("classes").get().then((doc) => {

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          let user = doc.data();
          user.id = doc.id;
          this.users.push(user);
        });
      } else {
        // TODO redirect to user list with error
        console.log(":'(");
      }
    }).catch(() => {

    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
