<template>
  <div class="container">
    <img src="../../../assets/logo.png" height="50">
    <h2>L'exercice est fini !</h2>
    <div class="panel-heading">
      <h3 class="panel-title text-center">Tu as répondu à {{ result }} calculs !</h3>

      <button class="main-btn" ><router-link to="/math/parameters">Retour aux paramètres</router-link></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MathSessionFinish',
  props: ['result']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a{
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: var(--main-bg-color);
  text-decoration: none;
}
</style>
