<template>
  <!-- 1 dropdown per type of operation
   When dropped, the option for the selected operation is displayed-->

  <div class="container">
    <div class="row align-items-center mh-50">
      <label for="first_name">Prénom: </label><input id="first_name" type="text" v-model="user.first_name">
    </div>
    <div class="row align-items-center mh-50">
      <label for="last_name">Nom de famille: </label><input id="last_name" type="text" v-model="user.last_name">
    </div>
    <label t-if="user.auth_code.length > 0">Code d'enregistrement: {{ user.auth_code }}</label>
    <label t-if="errorMessage.length > 0"> {{ errorMessage }}</label>
    <div class="row align-items-center mh-50">
      <button v-on:click="register">Enregistrer les changements</button>
    </div>
  </div>
</template>

<script>

import firebase from "firebase/app";

export default {
  name: 'User',
  props:{
    uid: String
  },
  data() {
    return {
      user: null,
      errorMessage: ""
    }
  },
  methods: {
    initView(){
      firebase.firestore().collection("users").doc(this.uid).get().then((doc) => {
        if(!doc.empty){
          this.user = doc.data();
        }else{
          // TODO redirect to user list with error
          console.log(":'(");
        }}).catch(() => {

      });
    },
    register() {
      firebase.firestore().collection("users").add({
        first_name: this.first_name,
        last_name: this.last_name,
        auth_code: this.generateUid()
      }).then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
      })
          .catch(() => {

          });


    }
  },
  created() {
    this.initView();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
