<template>
  <!-- 1 dropdown per type of operation
   When dropped, the option for the selected operation is displayed-->

    <div class="container">
    
      <img src="../assets/logo.png" height="50">
      <h2>Bienvenue sur Ecolier</h2>
      <div class="form-row align-items-center mh-50">
        <label for="login">Identifiant </label>
        <input id="login" type="text" v-model="options.login" />
      </div>
      <div class="form-row align-items-center mh-50">
        <label for="password">Mot de passe </label>
        <input id="password" type="password" v-model="options.password" />
      </div>

      <button class="main-btn" v-on:click="connect">Se connecter</button>

      <label t-if="errorMessage.length > 0"> {{ errorMessage }}</label>
    </div>
</template>

<script>
import firebase from "firebase/app";

export default {
  name: "Login",
  data() {
    return {
      errorMessage: "",
      options: {
        login: "",
        password: "",
      },
    };
  },
  methods: {
    connect() {
      if(this.options.login == "admin" && this.options.password== "admin"){
        this.$router.push("math/parameters");
      }else if(this.options.login == "user" && this.options.password== "user"){
        this.$router.push("math/session");
      }
      firebase
        .auth()
        .signInWithEmailAndPassword(this.options.login, this.options.password)
        .then((userCredential) => {
          // Signed in
          firebase
            .firestore()
            .collection("users")
            .where("user_auth_uid", "==", userCredential.user.uid)
            .limit(1)
            .get()
            .then((querySnapshot) => {
              if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                  let user = doc.data();
                  localStorage.user = user;
                  if (user.user_type == "1") {
                    this.$router.push("math/parameters");
                  } else if (user.user_type == "2") {
                    this.$router.push("math/session");
                  }
                });
              } else {
                this.$router.push({ name: "LinkUser" });
              }
            })
            .catch((error) => {
              console.log(error.code + " : " + error.message);
            });
        })
        .catch((error) => {
          if (error.code == "auth/user-not-found") {
            this.errorMessage =
              "Il n'y a pas d'utilisateur correspondant à cette combinaison Identifiant / Mot de passe";
          } else {
            console.log(error.code + " : " + error.message);
          }
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



</style>
