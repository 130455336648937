<template>
  <!-- 1 dropdown per type of operation
   When dropped, the option for the selected operation is displayed-->
  <div class="container">
    <img src="../assets/logo.png" height="50">
    <h2>Inscription sur Ecolier</h2>
    <div class="form-row ">
      <label for="login">Identifiant: </label><input id="login" type="text" v-model="options.login">
    </div>
    <div class="form-row">
      <label for="password">Mot de passe: </label><input id="password" type="password" v-model="options.password">
    </div>
    <label t-if="errorMessage.length > 0"> {{ errorMessage }}</label>
    <button class="main-btn" v-on:click="register">S'enregistrer</button>
  </div>
</template>

<script>

import firebase from "firebase/app";

export default {
  name: 'Register',
  data() {
    return {
      options: {
        login: '',
        password: ''
      },
      errorMessage: ""
    }
  },
  methods: {
    register() {
      firebase.auth().createUserWithEmailAndPassword(this.options.login, this.options.password)
          .then((userCredential) => {
            firebase.firestore().collection("users").where("auth_user_uid", "==", userCredential.user.uid).limit(1).get()
                .then((querySnapshot) => {
                  if(querySnapshot.exists) {
                    querySnapshot.forEach((doc) => {
                      let user = doc.data();
                      localStorage.user = user;
                      if (user.user_type == "1") {
                        this.$router.push('math/parameters');
                      } else if (user.user_type == "2") {
                        this.$router.push('math/session');
                      }
                    });
                  }else{
                    this.$router.push('LinkUser');
                  }
                })
                .catch(() => {

                });
          }).catch((error) => {
        // TODO if user already exist => Message
        var errorCode = error.code;
        var errorMessage = error.message;
        alert(errorCode + " : " + errorMessage)
      });


    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
