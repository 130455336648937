import Vue from 'vue'
import App from './App.vue'
import router from './router'

import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyD2aNRll5OEp2uY2UDTRrPikTB4uU56IXI",
  authDomain: "ecolio-9b508.firebaseapp.com",
  projectId: "ecolio-9b508",
  storageBucket: "ecolio-9b508.appspot.com",
  messagingSenderId: "225317210774",
  appId: "1:225317210774:web:0a7fa7406e2bff67e990b7",
  measurementId: "G-59CYLTFGGD"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
